import * as React from 'react'

import ContactSection from './contactSection'
import RankingContent from './rankingContent'

const IndexPage = ({slug, type, year = '2022'}) => {
  return (
    <>
      <div className="page page--index has-border-top">
        <div className="fluid-grid">
          <div className="lg:pt-10 lg:pb-20">
            <RankingContent
              isSinglePage={true}
              isIndex={true}
              currentSector="all"
              currentCriteria={slug}
              year={year} />
          </div>
        </div>
      </div>
      <ContactSection />
    </>
  )
}

export default IndexPage