import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import IndexPage from '../../components/indexPage'

const SeoPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/efficacite-seo" type="index">
      <SEO 
        title="Meilleurs sites en référencement : le classement SEO - 2021"
        description="	Quels sont les meilleurs sites en SEO de l'Observatoire de l'impact positif ? Découvrez les sites qui utilisent le mieux les critères SEO pour se démarquer."
        path="/2021/efficacite-seo"
        year="2021" />
      
      <Breadcrumb url="/2021/efficacite-seo" label="Efficacité SEO" year="2021" /> 

      <h1 className="sr-only">Efficacité SEO</h1>
      <IndexPage slug="seo" type="index" year="2021" />
    </Layout>
  )
  }

export default SeoPage

